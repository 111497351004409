import { useState, useEffect } from "react";
import { injectModels } from "../../redux/injectModels";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../constants";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { AuthService } from "../../services";

const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [orgID, setOrgID] = useState("");
  const [uriId, setUriId] = useState("");
  const [uriIdForgot, setUriIdforForgot] = useState("");
  const [uriImage, setUriImage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const uriData = queryParams.get("uri");
  const tokenData = queryParams.get("token");
  

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const referrerUrl = document.referrer;
    if(window.location.href=="http://bookings.zumohealth.co.uk/login" || window.location.href=="https://bookings.zumohealth.co.uk/login"){
		var uriData11 = "$2y$10$MqFTXXDeYZBdj2xenMTUDuJLSPtgBLYgZi54Ql/SMB4FPAKO1XsfW";
    }
    if (uriData || uriData11) {  
      setLoading(true);
      const orgDetails = await props.auth.getOrgById(
        uriData || uriData11,
        tokenData,
        referrerUrl
      );

      if (orgDetails.message.length > 0) {
        if (orgDetails.message[0].isLoggedIn) {
          localStorage.setItem(
            "accessToken",
            orgDetails.message[0].access_token
          );
          localStorage.setItem(
            "email",
            orgDetails.message[0].userData[0].email
          );
          localStorage.setItem(
            "Subscription_status",
            orgDetails.message[0].userData[0].subscription
          );
          localStorage.setItem(
            "trial",
            orgDetails.message[0].userData[0].trial
          );
          localStorage.setItem("user_id", orgDetails.message[0].userData[0].id);
          localStorage.setItem(
            "countary",
            orgDetails.message[0].userData[0].country
          );
          localStorage.setItem(
            "orgId",
            orgDetails.message[0].userData[0].organisation_id
          );
          localStorage.setItem(
            "checkoutURL",
            orgDetails.message[0].checkouturl
          );
          localStorage.setItem("sessionVal", orgDetails.message[0].sessionVal);
          localStorage.setItem("uriLogo", orgDetails.message[0].image);
		  if(uriData){
			localStorage.setItem("uripath", uriData);
		  }else if(uriData11){
			  localStorage.setItem("uripath", uriData11);
		  }
          localStorage.setItem("uriToken", tokenData);

          history.push(Routes.DASHBOARD);
        } else {
          let id = orgDetails.message[0].id;
          setUriIdforForgot(`/forgot-password?uri=${uriData || uriData11}`);
          setUriId(`/register?uri=${uriData || uriData11}&token=${tokenData}`);
          setOrgID("" + id + "");
          setUriImage(orgDetails.message && orgDetails.message[0].image);
          if(uriData){
			localStorage.setItem("uripath", uriData);
		  }else if(uriData11){
			  localStorage.setItem("uripath", uriData11);
		  }
          localStorage.setItem("uriToken", tokenData);
          localStorage.setItem("uriLogo", orgDetails.message[0].image);
          if (orgDetails) {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } else {
      localStorage.removeItem("uriLogo");
      localStorage.removeItem("uripath");
    }
  }, []);

  const handleEmailChange = (e) => {
    e.preventDefault();
    const val = e.target.value.trim();
    if (val === "") {
      setEmailError("Email is required");
    } else if (!emailRegex.test(val)) {
      setEmailError("Email is not valid");
    } else {
      setEmailError("");
    }

    setEmail(val);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setPassError("Password is required");
    } else {
      setPassError("");
    }
    setPassword(val.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    if (email === "") {
      setEmailError("Email is required");
      valid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Not a valid email");
      valid = false;
    } else {
      setEmailError("");
    }

    if (password === "") {
      setPassError("Password is required");
      valid = false;
    } else {
      setPassError("");
    }

    if (!valid) {
      return;
    } else {
      setError("");
      try {
        setLoading(true);
        const response = await AuthService.Login({
          email: email,
          password: password,
          organisation_id: orgID,
          tokenVal: tokenData ? tokenData : "",
        });
        if (response.success) {
          setLoading(false);
          if (response.data.subscription == 1 || response.data.trial == 1) {
            history.push(Routes.DASHBOARD);
          } else {
            history.push(Routes.PRODUCTSUBSCRIPTION);
          }
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error.response);
        if (error.message === "Request failed with status code 401") {
          toast.error("Invalid Email or Password, Please check !!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        } else {
          toast.error("Something went wrong, Please try again later", {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        }
      }
    }
  };

  return (
    <main>
      <section className="login-bg-image">
        {loading && (
          <div className="spinner">
            <div className="loader">
              {" "}
              <Circles
                height="110"
                width="110"
                color="#FFF"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        )}
        <div className="container">
          <section className="section register  d-flex flex-column align-items-center justify-content-center py-3">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-5 col-md-8  d-flex flex-column align-items-center justify-content-center">
                  <div className="d-flex justify-content-center pb-3">
                    <Link
                      to={
                        uriImage
                          ? `/login?uri=${uriData}&token=${tokenData}`
                          : Routes.DEFAULT
                      }
                      className="logo d-flex align-items-center w-auto"
                    >
                      {loading ? (
                        <img src="/assets/img/placeholder.png" alt="Loading" />
                      ) : (
                        <img src={uriImage || "/assets/img/logo.png"} alt="" />
                      )}
                    </Link>
                  </div>
                  {/* End Logo */}

                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="pt-1 pb-3">
                        <h5 className="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                      </div>
                      <form
                        onSubmit={handleSubmit}
                        className="row g-2 needs-validation account-login"
                      >
                        <div className="col-12">
                          <label htmlFor="yourUsername" className="form-label">
                            Email
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-regular fa-envelope" />{" "}
                            </span>
                            <input
                              type="text"
                              name="username"
                              className="form-control"
                              id="yourUsername"
                              value={email}
                              onChange={handleEmailChange}
                            />
                            <div className="invalid-feedback">
                              Please enter your Email ID.
                            </div>
                          </div>
                          {emailError && (
                            <span className="error">{emailError}</span>
                          )}
                        </div>
                        <div className="col-12">
                          <label htmlFor="yourPassword" className="form-label">
                            Password
                          </label>
                          <div className="input-group has-validation">
                            <span
                              className="input-group-text"
                              id="inputGroupPrepend"
                            >
                              <i className="fa-solid fa-lock" />
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control"
                              id="password"
                              value={password}
                              onChange={handlePasswordChange}
                            />
                            <button
                              type="button"
                              className="btn btn-outline-secondary eye-splash"
                              onClick={toggleShowPassword}
                            >
                              {showPassword ? (
                                <i className="fa-solid fa-eye-slash" />
                              ) : (
                                <i className="fa-solid fa-eye" />
                              )}
                            </button>
                            <div className="invalid-feedback">
                              Please enter your Password.
                            </div>
                          </div>
                          {passError && (
                            <span className="error">{passError}</span>
                          )}
                        </div>
                        <div className="col-12">
                          <div className="remMe">
                            <div className="form-check"></div>

                            <Link
                              to={
                                uriIdForgot
                                  ? uriIdForgot
                                  : Routes.FORGOT_PASSWORD
                              }
                              className="fwp"
                            >
                              Forgot Password?
                            </Link>
                          </div>
                        </div>
                        {error && (
                          <div className="col-xs-12 col-md-12 col-lg-12">
                            <div className="alert alert-danger">{error}</div>
                          </div>
                        )}
                        <div className="col-12 login">
                          <button
                            className="btn btn-primary w-100"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                        <div className="col-12 text-center create-account">
                          <p className="small mb-0">
                            Don't have an account?{" "}
                            <Link to={uriId ? uriId : "/register"}>
                              Create an account
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </main>
  );
};

export default injectModels(["auth"])(Login);

// File Created By sourav on 01/05/2023
